import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse } from 'axios'

interface OutletListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: OutletListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<OutletListState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1

      if (query.name) {
        params.search = query.name
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/outlets', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
          // reject(error.response?.data);
        })
    })
  },

}

export const $mutations: MutationTree<OutletListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.outlets

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

export const $getters: GetterTree<OutletListState, RootState> = {}

export const STORE_KEY = '$_outlet_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
